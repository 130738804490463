import { css } from "@emotion/react";
import React from "react";

export const LandingLayout = (page: React.ReactElement) => {
  return (
    <div
      css={css({
        position: "relative",
        width: "100%",
        height: "100%",
      })}
    >
      {page}
    </div>
  );
};
