import { TextButton } from "@/components/buttons/text-button/TextButton";
import { GTM_CLASS } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";

type TermsPageTextButtonProps = Partial<Pick<HUI.TextProps, "kind" | "colour">>;

export const TermsPageTextButton = ({
  kind = HENGE.TEXT_KIND.body1,
  colour = HENGE.SEMANTIC_TEXT_COLOUR["text-primary"],
}: TermsPageTextButtonProps) => {
  return (
    <TextButton
      className={GTM_CLASS.Page_Button.terms_page}
      kind={kind}
      colour={colour}
      onClick={() => {
        window.open("/terms", "_blank");
      }}
    >
      Terms of Use
    </TextButton>
  );
};
