import { IconButton } from "@/components/buttons/icon-button/IconButton";
import { GTM_CLASS } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import HENGE from "@henge-inc/global-types";
import { ICON, IconProps } from "@henge-inc/ui/v1_1";

type TwitterXIconButtonProps = Partial<Pick<IconProps, "colour" | "size">>;

export const HengeOfficialTwitterXIconButton = ({
  // kind = HENGE.ICON_KIND.regular,
  colour = HENGE.THEME_COLOUR["text-mono-inverted"],
  size = HENGE.DETAILED_SIZE.medium,
}: TwitterXIconButtonProps) => {
  return (
    <IconButton
      className={`${GTM_CLASS.Link_Button.official_sns_twitter} bg-white rounded-full w-9 h-9`}
      iconProps={{
        icon: ICON.TwitterX,
        props: { colour, size },
      }}
      onClick={() => {
        window.open("https://x.com/hengehq", "_blank");
      }}
    />
  );
};
