import { GTM_CLASS } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import { useRouter } from "next/router";

type SignupPageButtonProps = Pick<
  HUI.ButtonProps,
  "kind" | "colour" | "size" | "textStyleProps" | "fullWidth" | "shape"
>;

export const SignupPageButton = ({
  kind = HENGE.BUTTON_KIND.filled,
  colour = HENGE.GRAYSCALE_COLOUR.mono,
  size = HENGE.DETAILED_SIZE.small,
  fullWidth = false,
  shape = HENGE.BUTTON_SHAPE.square,
}: SignupPageButtonProps) => {
  const router = useRouter();

  return (
    <HUI.Button
      className={GTM_CLASS.Page_Button.sign_up_page}
      kind={kind}
      colour={colour}
      fullWidth={fullWidth}
      size={size}
      shape={shape}
      onClick={() => {
        router.push("/signup");
      }}
    >
      Sign up
    </HUI.Button>
  );
};
