import { HengeIconLogo } from "@/components/logo/HengeIconLogo";
import { HengeTextLogo } from "@/components/logo/HengeTextLogo";
import { GTM_CLASS } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import { css } from "@emotion/react";
import { useRouter } from "next/router";

export const HomePageButton = () => {
  const router = useRouter();

  return (
    <button
      className={GTM_CLASS.Page_Button.home_page}
      css={css({
        pointerEvents: "auto",
        cursor: "pointer",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
      })}
      onClick={() => {
        router.push("/");
      }}
    >
      <HengeIconLogo size={24} />
      <HengeTextLogo />
    </button>
  );
};
