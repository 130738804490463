import { IconButton } from "@/components/buttons/icon-button/IconButton";
import { GTM_CLASS } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import HENGE from "@henge-inc/global-types";
import { ICON, IconProps } from "@henge-inc/ui/v1_1";

type DiscordIconButtonProps = Partial<
  Pick<IconProps, "colour" | "size" | "kind">
>;

export const HengeOfficialDiscordIconButton = ({
  kind = HENGE.ICON_KIND.regular,
  colour = HENGE.THEME_COLOUR["text-mono-inverted"],
  size = HENGE.DETAILED_SIZE.x3Large,
}: DiscordIconButtonProps) => {
  return (
    <IconButton
      className={`${GTM_CLASS.Link_Button.official_sns_discord} bg-white rounded-full w-9 h-9`}
      iconProps={{
        icon: ICON.Discord,
        props: { colour, size },
        kind,
      }}
      onClick={() => {
        window.open("https://discord.gg/qQeM3REU8K", "_blank");
      }}
    />
  );
};
