import HENGE, { ICON_KIND } from "@henge-inc/global-types";
import { IconProps, IconType, i } from "@henge-inc/ui/v1_1";
import React from "react";

interface IconButtonProps {
  id?: string;
  className?: string;
  iconProps: {
    icon: IconType;
    props: Pick<IconProps, "colour" | "size">;
    kind?: HENGE.IconKindType;
  };
  onClick: () => void;
}

export const IconButton = ({
  id,
  className,
  iconProps,
  onClick,
}: IconButtonProps) => {
  return (
    <button
      id={id}
      className={className}
      style={{
        flexShrink: 0,
        cursor: "pointer",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      {React.createElement(i[iconProps.icon], {
        ...iconProps.props,
        kind: ICON_KIND.light,
      })}
    </button>
  );
};
