import { HengeOfficialDiscordIconButton } from "@/components/buttons/icon-button/HengeOfficialDiscordIconButton";
import { HengeOfficialInstagramIconButton } from "@/components/buttons/icon-button/HengeOfficialInstagramIconButton";
import { HengeOfficialTwitterXIconButton } from "@/components/buttons/icon-button/HengeOfficialTwitterXIconButton";
import { CompanyPageTextButton } from "@/components/buttons/text-button/CompanyPageTextButton";
import { ContactMailtoTextButton } from "@/components/buttons/text-button/ContactMailtoTextButton";
import { CookiesPageTextButton } from "@/components/buttons/text-button/CookiesPageTextButton";
import { PrivacyPageTextButton } from "@/components/buttons/text-button/PrivacyPageTextButton";
import { TermsPageTextButton } from "@/components/buttons/text-button/TermsPageTextButton";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";

export const LandingFooter = () => {
  return (
    <div className="relative flex flex-row justify-center items-center gap-6 w-full py-20 bg-black ">
      {/* <div className="flex flex-row justify-center items-center w-full pb-10">
        <HUI.i.HengeCatchphrase
          colour={HENGE.THEME_COLOUR["text-mono-inverted"]}
          size={HENGE.DETAILED_SIZE.x3Large}
        />
      </div> */}
      <div className="w-full flex flex-col items-center gap-8 max-w-[1200px] px-4">
        <div className="flex flex-row justify-center items-center gap-6">
          <HengeOfficialInstagramIconButton
            colour={HENGE.PURE_COLOUR["text-black"]}
            kind={HENGE.ICON_KIND.solid}
          />
          <HengeOfficialTwitterXIconButton
            colour={HENGE.PURE_COLOUR["text-black"]}
          />
          <HengeOfficialDiscordIconButton
            colour={HENGE.PURE_COLOUR["text-black"]}
          />
        </div>
        <nav className="max-[560px]:flex-col flex flex-row justify-center items-center gap-6 w-full">
          <CompanyPageTextButton
            colour={HENGE.SEMANTIC_TEXT_COLOUR["text-mono-inverted"]}
          />
          <ContactMailtoTextButton
            colour={HENGE.SEMANTIC_TEXT_COLOUR["text-mono-inverted"]}
          />
          <TermsPageTextButton
            colour={HENGE.SEMANTIC_TEXT_COLOUR["text-mono-inverted"]}
          />
          <PrivacyPageTextButton
            colour={HENGE.SEMANTIC_TEXT_COLOUR["text-mono-inverted"]}
          />
          <CookiesPageTextButton
            colour={HENGE.SEMANTIC_TEXT_COLOUR["text-mono-inverted"]}
          />
        </nav>

        <div className="flex flex-col gap-2">
          <HUI.Text
            kind={HENGE.TEXT_KIND.small}
            colour={HENGE.THEME_COLOUR["text-mono-inverted"]}
            align={HENGE.TEXT_ALIGN.center}
          >
            We use cookies. For more, see our{" "}
            <a href="/cookies" target="_blank">
              Cookies Policy
            </a>
            .
          </HUI.Text>
          <HUI.Text
            kind={HENGE.TEXT_KIND.small}
            colour={HENGE.THEME_COLOUR["text-mono-inverted"]}
            align={HENGE.TEXT_ALIGN.center}
          >
            Copyright &copy; {new Date().getFullYear()} Henge Inc.
          </HUI.Text>
        </div>
      </div>
    </div>
  );
};
