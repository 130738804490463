import { css } from "@emotion/react";
import HUI from "@henge-inc/ui/v1_2";
import textLogoFile from "@public/assets/logos/text-logo.webp";
import Image from "next/image";

export const HengeTextLogo = () => {
  const theme = HUI.useTheme();

  return (
    <Image
      css={css({
        userSelect: "none",
        filter: theme.mode === HUI.THEME_MODE_ENUM.night ? "invert()" : "",
      })}
      draggable={false}
      src={textLogoFile}
      property={"true"}
      width={72}
      height={24}
      alt={"Henge"}
    />
  );
};
