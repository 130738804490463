import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";

interface TextButtonProps extends Pick<HUI.TextProps, "kind" | "colour"> {
  id?: string;
  className?: string;
  children: string;
  onClick: () => void;
}

export const TextButton = ({
  kind,
  colour,
  id,
  className,
  children,
  onClick,
}: TextButtonProps) => {
  return (
    <button
      id={id}
      className={className}
      style={{
        flexShrink: 0,
        cursor: "pointer",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <HUI.Text kind={kind} colour={colour} align={HENGE.TEXT_ALIGN.center}>
        {children}
      </HUI.Text>
    </button>
  );
};
