interface HengeIconLogoProps {
  size: number;
}

export const HengeIconLogo = ({ size }: HengeIconLogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 0C28.6538 0 0 28.6538 0 64V448C0 483.346 28.6538 512 64 512H448C483.346 512 512 483.346 512 448V64C512 28.6538 483.346 0 448 0H64ZM178.259 102.154C194.737 168.252 212.787 240.656 212.787 408.068C212.787 429.708 213.029 443.009 213.412 451.777C213.924 463.514 224.116 472 235.864 472H388C401.255 472 412 461.255 412 448V64C412 50.7452 401.255 40 388 40H193.069C177.992 40 166.667 53.7472 170.061 68.4375C172.683 79.7864 175.448 90.8784 178.259 102.154Z"
        fill="black"
      />
      <circle cx="328" cy="204" r="80" fill="black" />
    </svg>
  );
};
