import { GTM_CLASS } from "@/plugins/google-tag-manager/google-tag-manager-constants";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";
import { useRouter } from "next/router";

export const LoginPageButton = () => {
  const router = useRouter();

  return (
    <HUI.Button
      className={GTM_CLASS.Page_Button.log_in_page}
      kind={HENGE.BUTTON_KIND.borderless}
      colour={HENGE.GRAYSCALE_COLOUR.mono}
      size={HENGE.DETAILED_SIZE.small}
      onClick={() => {
        router.push("/login");
      }}
    >
      Log in
    </HUI.Button>
  );
};
