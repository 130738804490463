import { TextButton } from "@/components/buttons/text-button/TextButton";
import HENGE from "@henge-inc/global-types";
import HUI from "@henge-inc/ui/v1_2";

type CookiesPageTextButtonProps = Partial<
  Pick<HUI.TextProps, "kind" | "colour">
>;

export const CookiesPageTextButton = ({
  kind = HENGE.TEXT_KIND.body1,
  colour = HENGE.SEMANTIC_TEXT_COLOUR["text-primary"],
}: CookiesPageTextButtonProps) => {
  return (
    <TextButton
      kind={kind}
      colour={colour}
      onClick={() => {
        window.open("/cookies", "_blank");
      }}
    >
      Cookies Policy
    </TextButton>
  );
};
