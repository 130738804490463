import { HomePageButton } from "@/components/buttons/HomePageButton";
import { LoginPageButton } from "@/components/buttons/LoginPageButton";
import { SignupPageButton } from "@/components/buttons/SignupPageButton";

export const FLOATING_HEADER_MAX_WIDTH = 1200;
export const FLOATING_HEADER_HEIGHT = 56;

interface LandingHeaderProps {
  maxWidth?: number;
  hideButtons?: boolean;
}

export const LandingHeader = ({
  maxWidth = FLOATING_HEADER_MAX_WIDTH,
  hideButtons = false,
}: LandingHeaderProps) => {
  return (
    <div
      className={`bg-transparent flex items-center justify-center fixed top-0 left-[50%] w-full h-[${FLOATING_HEADER_HEIGHT}px] m-0 py-4 transform -translate-x-1/2 z-1000 z-50`}
      style={{
        transition: "opacity 0.5s var(--ease-out)",
      }}
    >
      <div className={`flex px-4 w-full justify-between max-w-[${maxWidth}px]`}>
        <HomePageButton />
        {!hideButtons && (
          <div className="flex wrap flex-row justify-center items-center gap-2">
            <LoginPageButton />
            <SignupPageButton />
          </div>
        )}
      </div>
    </div>
  );
};
